@charset "UTF-8";
/* -webkit-appearance */
::-webkit-file-upload-button {
  -webkit-appearance: button;
}

[type="button"] {
  -webkit-appearance: none;
}

[type="checkbox"] {
  -webkit-appearance: checkbox;
}

[type="radio"] {
  -webkit-appearance: radio;
}

[type="reset"] {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

[type="submit"] {
  -webkit-appearance: none;
}

button {
  -webkit-appearance: none;
}

button {
  -webkit-appearance: none;
}

input {
  -webkit-appearance: none;
}

optgroup {
  -webkit-appearance: none;
}

select {
  -webkit-appearance: none;
}

textarea {
  -webkit-appearance: none;
}

/* -webkit-tap-highlight-color */
html {
  -webkit-tap-highlight-color: transparent;
}

/* -webkit-text-size-adjust */
html {
  -webkit-text-size-adjust: 100%;
}

/* appearance */
[type="button"] {
  appearance: none;
}

[type="checkbox"] {
  appearance: checkbox;
}

[type="radio"] {
  appearance: radio;
}

[type="reset"] {
  appearance: none;
}

[type="submit"] {
  appearance: none;
}

button {
  appearance: none;
}

button {
  appearance: none;
}

input {
  appearance: none;
}

optgroup {
  appearance: none;
}

select {
  appearance: none;
}

textarea {
  appearance: none;
}

/* background */
button {
  background: transparent;
}

input {
  background: transparent;
}

optgroup {
  background: transparent;
}

select {
  background: transparent;
}

textarea {
  background: transparent;
}

/* background-color */
a {
  background-color: transparent;
}

/* border-collapse */
table {
  border-collapse: collapse;
}

/* border-radius */
button {
  border-radius: 0;
}

input {
  border-radius: 0;
}

optgroup {
  border-radius: 0;
}

select {
  border-radius: 0;
}

textarea {
  border-radius: 0;
}

/* border-spacing */
table {
  border-spacing: 0;
}

/* border-style */
* {
  border-style: solid;
}

::after {
  border-style: solid;
}

::before {
  border-style: solid;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
}

button::-moz-focus-inner {
  border-style: none;
}

/* border-top-width */
hr {
  border-top-width: 1px;
}

/* border-width */
* {
  border-width: 0;
}

::after {
  border-width: 0;
}

::before {
  border-width: 0;
}

/* bottom */
sub {
  bottom: -0.25em;
}

/* box-sizing */
* {
  box-sizing: border-box;
}

::after {
  box-sizing: border-box;
}

::before {
  box-sizing: border-box;
}

hr {
  box-sizing: content-box;
}

/* clear */
hr {
  clear: both;
}

/* color */
a {
  color: inherit;
}

button {
  color: inherit;
}

hr {
  color: inherit;
}

input {
  color: inherit;
}

legend {
  color: inherit;
}

optgroup {
  color: inherit;
}

select {
  color: inherit;
}

textarea {
  color: inherit;
}

/* cursor */
[type="button"] {
  cursor: pointer;
}

[type="button"][disabled] {
  cursor: default;
}

[type="reset"] {
  cursor: pointer;
}

[type="reset"][disabled] {
  cursor: default;
}

[type="submit"] {
  cursor: pointer;
}

[type="submit"][disabled] {
  cursor: default;
}

button {
  cursor: pointer;
}

button[disabled] {
  cursor: default;
}

label[for] {
  cursor: pointer;
}

/* display */
[hidden] {
  display: none;
}

details {
  display: block;
}

legend {
  display: table;
}

main {
  display: block;
}

select::-ms-expand {
  display: none;
}

summary {
  display: list-item;
}

template {
  display: none;
}

/* font */
::-webkit-file-upload-button {
  font: inherit;
}

button {
  font: inherit;
}

input {
  font: inherit;
}

optgroup {
  font: inherit;
}

select {
  font: inherit;
}

textarea {
  font: inherit;
}

/* font-family */
code {
  font-family: monospace, monospace;
}

kbd {
  font-family: monospace, monospace;
}

pre {
  font-family: monospace, monospace;
}

samp {
  font-family: monospace, monospace;
}

/* font-size */
code {
  font-size: inherit;
}

h1 {
  font-size: inherit;
}

h2 {
  font-size: inherit;
}

h3 {
  font-size: inherit;
}

h4 {
  font-size: inherit;
}

h5 {
  font-size: inherit;
}

h6 {
  font-size: inherit;
}

kbd {
  font-size: inherit;
}

pre {
  font-size: inherit;
}

samp {
  font-size: inherit;
}

small {
  font-size: 80%;
}

sub {
  font-size: 75%;
}

sup {
  font-size: 75%;
}

/* font-style */
address {
  font-style: inherit;
}

/* font-weight */
b {
  font-weight: bolder;
}

dt {
  font-weight: bold;
}

h1 {
  font-weight: inherit;
}

h2 {
  font-weight: inherit;
}

h3 {
  font-weight: inherit;
}

h4 {
  font-weight: inherit;
}

h5 {
  font-weight: inherit;
}

h6 {
  font-weight: inherit;
}

strong {
  font-weight: bolder;
}

th {
  font-weight: bold;
}

/* height */
[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

hr {
  height: 0;
}

/* line-height */
h1 {
  line-height: inherit;
}

h2 {
  line-height: inherit;
}

h3 {
  line-height: inherit;
}

h4 {
  line-height: inherit;
}

h5 {
  line-height: inherit;
}

h6 {
  line-height: inherit;
}

html {
  line-height: 1.15;
}

sub {
  line-height: 0;
}

sup {
  line-height: 0;
}

/* list-style */
ol {
  list-style: none;
}

ul {
  list-style: none;
}

/* margin */
address {
  margin: 0;
}

blockquote {
  margin: 0;
}

body {
  margin: 0;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  line-height: 1.6;
  font-size: 16px;
}

button {
  margin: 0;
}

dl {
  margin: 0;
}

fieldset {
  margin: 0;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

h1 {
  margin: 0;
}

h2 {
  margin: 0;
}

h3 {
  margin: 0;
}

h4 {
  margin: 0;
}

h5 {
  margin: 0;
}

h6 {
  margin: 0;
}

hr {
  margin: 0;
}

iframe {
  margin: 0;
}

input {
  margin: 0;
}

ol {
  margin: 0;
}

optgroup {
  margin: 0;
}

p {
  margin: 0;
}

pre {
  margin: 0;
}

select {
  margin: 0;
}

table {
  margin: 0;
}

textarea {
  margin: 0;
}

ul {
  margin: 0;
}

/* margin-left */
dd {
  margin-left: 0;
}

/* max-width */
legend {
  max-width: 100%;
}

/* min-width */
fieldset {
  min-width: 0;
}

/* outline */
[contenteditable] {
  outline: none;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

button {
  outline: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

input {
  outline: 0;
}

optgroup {
  outline: 0;
}

select {
  outline: 0;
}

textarea {
  outline: 0;
}

/* outline-offset */
[type="search"] {
  outline-offset: -2px;
}

/* overflow */
button {
  overflow: visible;
}

hr {
  overflow: visible;
}

input {
  overflow: visible;
}

textarea {
  overflow: auto;
}

/* padding */
[type="button"]::-moz-focus-inner {
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  padding: 0;
}

button {
  padding: 0;
}

button::-moz-focus-inner {
  padding: 0;
}

fieldset {
  padding: 0;
}

input {
  padding: 0;
}

legend {
  padding: 0;
}

ol {
  padding: 0;
}

optgroup {
  padding: 0;
}

option {
  padding: 0;
}

select {
  padding: 0;
}

td {
  padding: 0;
}

textarea {
  padding: 0;
}

th {
  padding: 0;
}

ul {
  padding: 0;
}

/* position */
sub {
  position: relative;
}

sup {
  position: relative;
}

/* text-align */
button {
  text-align: inherit;
}

caption {
  text-align: left;
}

input {
  text-align: inherit;
}

optgroup {
  text-align: inherit;
}

select {
  text-align: inherit;
}

textarea {
  text-align: inherit;
}

th {
  text-align: left;
}

/* text-decoration */
a {
  text-decoration: none;
}

abbr[title] {
  text-decoration: underline dotted;
}

abbr[title] {
  text-decoration: underline;
}

/* text-transform */
button {
  text-transform: none;
}

select {
  text-transform: none;
}

/* top */
sup {
  top: -0.5em;
}

/* vertical-align */
button {
  vertical-align: middle;
}

embed {
  vertical-align: bottom;
}

iframe {
  vertical-align: bottom;
}

img {
  vertical-align: bottom;
  max-width: 100%;
}

input {
  vertical-align: middle;
}

object {
  vertical-align: bottom;
}

optgroup {
  vertical-align: middle;
}

progress {
  vertical-align: baseline;
}

select {
  vertical-align: middle;
}

sub {
  vertical-align: baseline;
}

sup {
  vertical-align: baseline;
}

td {
  vertical-align: top;
}

textarea {
  vertical-align: middle;
}

th {
  vertical-align: top;
}

/* white-space */
legend {
  white-space: normal;
}

.inner {
  max-width: 1000px;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .inner {
    padding: 0 10px;
  }
}

.header-inner {
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main {
  position: relative;
}

.main-copy {
  position: absolute;
  width: 70%;
  height: 100%;
  top: 13%;
  right: 0;
  left: 0;
  display: block;
  margin: auto;
}

@media screen and (max-width: 640px) {
  .main-copy {
    width: 90%;
    height: auto;
    top: 11%;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.main-copy img {
  width: 100%;
}

.main-btn {
  position: absolute;
  bottom: 23%;
  left: 16%;
  width: 24%;
}

@media screen and (max-width: 640px) {
  .main-btn {
    width: 68%;
    left: 0;
    right: 0;
    margin: auto;
    bottom: auto;
    top: 35%;
  }
}

.main-bg {
  width: 100%;
}

.main-pc {
  display: none;
}

@media screen and (max-width: 640px) {
  .main-pc {
    display: block;
    position: absolute;
    top: 53%;
    right: 10px;
    width: 94%;
  }
}

.footer {
  height: 60px;
  line-height: 60px;
  background: #257086;
}

.footer p {
  text-align: center;
  font-size: 14px;
}

.btn a {
  display: block;
  max-width: 300px;
  background: #175466;
  color: #fff;
  margin-top: 20px;
  padding: 15px 0;
  width: 100%;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 640px) {
  .btn a {
    margin-right: auto;
    margin-left: auto;
  }
}

.flex1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex1._top {
  align-items: flex-start;
}

.flex1._center {
  align-items: center;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:after {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 2;
}

.flex1 .box {
  width: calc(100% / 1 - 1%);
  margin-bottom: 2%;
}

@media screen and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
}

.flex1 .box img {
  width: 100% !important;
}

.flex2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex2._top {
  align-items: flex-start;
}

.flex2._center {
  align-items: center;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:after {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 2;
}

.flex2 .box {
  width: calc(100% / 2 - 1%);
  margin-bottom: 2%;
}

@media screen and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
}

.flex2 .box img {
  width: 100% !important;
}

.flex3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex3._top {
  align-items: flex-start;
}

.flex3._center {
  align-items: center;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:after {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 2;
}

.flex3 .box {
  width: calc(100% / 3 - 1%);
  margin-bottom: 2%;
}

@media screen and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
}

.flex3 .box img {
  width: 100% !important;
}

.flex4 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex4._top {
  align-items: flex-start;
}

.flex4._center {
  align-items: center;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:after {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 2;
}

.flex4 .box {
  width: calc(100% / 4 - 1%);
  margin-bottom: 2%;
}

@media screen and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
}

.flex4 .box img {
  width: 100% !important;
}

.bg100 {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
}

.mg1 {
  margin: 1em;
}

.mgT1 {
  margin-top: 1em;
}

.mgR1 {
  margin-right: 1em;
}

.mgB1 {
  margin-bottom: 1em;
}

.mgL1 {
  margin-left: 1em;
}

.pd1 {
  padding: 1em;
}

.pdT1 {
  padding-top: 1em;
}

.pdR1 {
  padding-right: 1em;
}

.pdB1 {
  padding-bottom: 1em;
}

.pdL1 {
  padding-left: 1em;
}

.mg2 {
  margin: 2em;
}

.mgT2 {
  margin-top: 2em;
}

.mgR2 {
  margin-right: 2em;
}

.mgB2 {
  margin-bottom: 2em;
}

.mgL2 {
  margin-left: 2em;
}

.pd2 {
  padding: 2em;
}

.pdT2 {
  padding-top: 2em;
}

.pdR2 {
  padding-right: 2em;
}

.pdB2 {
  padding-bottom: 2em;
}

.pdL2 {
  padding-left: 2em;
}

.mg3 {
  margin: 3em;
}

.mgT3 {
  margin-top: 3em;
}

.mgR3 {
  margin-right: 3em;
}

.mgB3 {
  margin-bottom: 3em;
}

.mgL3 {
  margin-left: 3em;
}

.pd3 {
  padding: 3em;
}

.pdT3 {
  padding-top: 3em;
}

.pdR3 {
  padding-right: 3em;
}

.pdB3 {
  padding-bottom: 3em;
}

.pdL3 {
  padding-left: 3em;
}

.mg4 {
  margin: 4em;
}

.mgT4 {
  margin-top: 4em;
}

.mgR4 {
  margin-right: 4em;
}

.mgB4 {
  margin-bottom: 4em;
}

.mgL4 {
  margin-left: 4em;
}

.pd4 {
  padding: 4em;
}

.pdT4 {
  padding-top: 4em;
}

.pdR4 {
  padding-right: 4em;
}

.pdB4 {
  padding-bottom: 4em;
}

.pdL4 {
  padding-left: 4em;
}

.mg5 {
  margin: 5em;
}

.mgT5 {
  margin-top: 5em;
}

.mgR5 {
  margin-right: 5em;
}

.mgB5 {
  margin-bottom: 5em;
}

.mgL5 {
  margin-left: 5em;
}

.pd5 {
  padding: 5em;
}

.pdT5 {
  padding-top: 5em;
}

.pdR5 {
  padding-right: 5em;
}

.pdB5 {
  padding-bottom: 5em;
}

.pdL5 {
  padding-left: 5em;
}

.mg6 {
  margin: 6em;
}

.mgT6 {
  margin-top: 6em;
}

.mgR6 {
  margin-right: 6em;
}

.mgB6 {
  margin-bottom: 6em;
}

.mgL6 {
  margin-left: 6em;
}

.pd6 {
  padding: 6em;
}

.pdT6 {
  padding-top: 6em;
}

.pdR6 {
  padding-right: 6em;
}

.pdB6 {
  padding-bottom: 6em;
}

.pdL6 {
  padding-left: 6em;
}

.mg7 {
  margin: 7em;
}

.mgT7 {
  margin-top: 7em;
}

.mgR7 {
  margin-right: 7em;
}

.mgB7 {
  margin-bottom: 7em;
}

.mgL7 {
  margin-left: 7em;
}

.pd7 {
  padding: 7em;
}

.pdT7 {
  padding-top: 7em;
}

.pdR7 {
  padding-right: 7em;
}

.pdB7 {
  padding-bottom: 7em;
}

.pdL7 {
  padding-left: 7em;
}

.mg8 {
  margin: 8em;
}

.mgT8 {
  margin-top: 8em;
}

.mgR8 {
  margin-right: 8em;
}

.mgB8 {
  margin-bottom: 8em;
}

.mgL8 {
  margin-left: 8em;
}

.pd8 {
  padding: 8em;
}

.pdT8 {
  padding-top: 8em;
}

.pdR8 {
  padding-right: 8em;
}

.pdB8 {
  padding-bottom: 8em;
}

.pdL8 {
  padding-left: 8em;
}

.mg9 {
  margin: 9em;
}

.mgT9 {
  margin-top: 9em;
}

.mgR9 {
  margin-right: 9em;
}

.mgB9 {
  margin-bottom: 9em;
}

.mgL9 {
  margin-left: 9em;
}

.pd9 {
  padding: 9em;
}

.pdT9 {
  padding-top: 9em;
}

.pdR9 {
  padding-right: 9em;
}

.pdB9 {
  padding-bottom: 9em;
}

.pdL9 {
  padding-left: 9em;
}

.mg10 {
  margin: 10em;
}

.mgT10 {
  margin-top: 10em;
}

.mgR10 {
  margin-right: 10em;
}

.mgB10 {
  margin-bottom: 10em;
}

.mgL10 {
  margin-left: 10em;
}

.pd10 {
  padding: 10em;
}

.pdT10 {
  padding-top: 10em;
}

.pdR10 {
  padding-right: 10em;
}

.pdB10 {
  padding-bottom: 10em;
}

.pdL10 {
  padding-left: 10em;
}

.flex3cl {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 50px auto 0;
  width: 100%;
}

@media screen and (max-width: 834px) {
  .flex3cl {
    margin-top: 20px;
  }
}

.flex3cl .box {
  width: 33%;
}

@media screen and (max-width: 834px) {
  .flex3cl .box {
    width: 50%;
    margin-top: 50px;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl .box {
    width: 100%;
    margin: 30px auto 0;
  }
}

.flex3cl .box img {
  display: block;
  margin: 0 auto 20px;
  width: 100%;
}

@media screen and (max-width: 640px) {
  .flex3cl .box img {
    width: 75%;
  }
}

.pd100 {
  padding: 100px 0;
}

.text {
  line-height: 2;
}

.white {
  color: #fff;
}

.bg-main {
  background: #175466;
}

.bg-gray {
  background: #f6f6f6;
}

.title-wrap {
  text-align: center;
}

.title-01 {
  font-size: 48px;
  font-weight: 900;
}

.h2-title {
  font-size: 46px;
  font-family: noto-sans-cjk-jp, sans-serif;
  font-weight: 900;
  font-style: normal;
  position: relative;
  display: inline-block;
  line-height: 1.3;
}

@media screen and (max-width: 640px) {
  .h2-title {
    font-size: 36px;
  }
}

.h2-title:before {
  position: absolute;
  content: "";
  background-color: #000;
  height: 3px;
  width: 100%;
  bottom: 0;
  left: 0;
}

.h2-title .title-big {
  font-size: 66px;
  padding-bottom: 20px;
  color: #175466;
  display: inline-block;
  position: relative;
}

@media screen and (max-width: 640px) {
  .h2-title .title-big {
    font-size: 50px;
  }
}

.h2-title .title-big:before {
  position: absolute;
  content: "";
  background-color: #175466;
  height: 3px;
  width: 100%;
  bottom: 0;
  left: 0;
}

.h2-title br {
  display: none;
}

@media screen and (max-width: 834px) {
  .h2-title br {
    display: block;
  }
}

.demilight {
  font-family: noto-sans-cjk-jp, sans-serif;
  font-weight: 300;
  font-style: normal;
}

.bold {
  font-family: noto-sans-cjk-jp, sans-serif;
  font-weight: 700;
  font-style: normal;
}

html {
  visibility: hidden;
}

html.wf-active, html.loading-delay {
  visibility: visible;
}

body {
  overflow-x: hidden;
}

.sec._01 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
}

.sec._01 > img {
  width: 40%;
}

.sec._01 > div {
  width: 58%;
}

@media screen and (max-width: 834px) {
  .sec._01 {
    flex-direction: column-reverse;
  }
}

.sec._01 .sec-img {
  margin-bottom: -7rem;
}

@media screen and (max-width: 834px) {
  .sec._01 .sec-img {
    width: 60%;
    margin: 10% auto -7rem;
  }
}

@media screen and (max-width: 834px) {
  .sec._01 .sec-inner {
    width: 100%;
  }
}

@media screen and (max-width: 834px) {
  .sec._01 .sec-title {
    text-align: center;
  }
}

.sec._02 .sec-text {
  margin: 50px auto 70px;
  font-size: 18px;
}

@media screen and (max-width: 640px) {
  .sec._02 .sec-text {
    font-size: 16px;
    margin: 50px auto 50px;
  }
}

.sec.cont .cont-titlebox {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
}

.sec.cont .cont-titlebox .cont-title {
  text-align: center;
  padding: 25px 0;
  font-size: 50px;
  font-family: noto-sans-cjk-jp, sans-serif;
  font-weight: 700;
  font-style: normal;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 834px) {
  .sec.cont .cont-titlebox .cont-title {
    font-size: 40px;
  }
}

@media screen and (max-width: 640px) {
  .sec.cont .cont-titlebox .cont-title {
    font-size: 30px;
  }
}

.sec.cont .cont-bottom {
  background-color: #257086;
  padding: 30px 0;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  position: relative;
  z-index: 1;
}

.sec.cont .cont-bottom .cont-info {
  width: 100%;
  background-color: #fff;
  padding: 25px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1000px) {
  .sec.cont .cont-bottom .cont-info {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
}

.sec.cont .cont-bottom .cont-info img {
  width: 100%;
}

.sec.cont .cont-bottom .cont-info .cont-info1 {
  max-width: 490px !important;
}

.sec.cont .cont-bottom .cont-info .cont-info2 {
  max-width: 410px !important;
}

@media (max-width: 1000px) {
  .sec.cont .cont-bottom .cont-info .cont-info2 {
    display: block;
    margin-top: 20px;
  }
}

.sec.cont .cont-bottom .cont-info .human {
  position: absolute;
  bottom: 0;
  left: 66%;
  max-width: 260px;
  z-index: -3;
}

@media (max-width: 1200px) {
  .sec.cont .cont-bottom .cont-info .human {
    left: auto;
    right: 0;
  }
}

@media (max-width: 1000px) {
  .sec.cont .cont-bottom .cont-info .human {
    display: none;
  }
}

.sec._03 .sec3box {
  margin: 50px auto 0;
  position: relative;
  max-width: 1300px;
}

.sec._03 .sec3box .pc-img {
  display: block;
  margin: 0 auto;
}

@media (max-width: 1300px) {
  .sec._03 .sec3box .pc-img {
    width: 65%;
  }
}

.sec._03 .sec3box .sec3-text {
  position: absolute;
}

@media (max-width: 1000px) {
  .sec._03 .sec3box .sec3-text {
    position: inherit;
    margin-top: 30px;
    width: 90%;
    margin: 40px auto 0;
  }
}

@media screen and (max-width: 834px) {
  .sec._03 .sec3box .sec3-text {
    width: 100%;
  }
}

.sec._03 .sec3box .sec3-text h3 {
  color: #175466;
  font-size: 26px;
  font-weight: bold;
  position: relative;
  padding-bottom: 5px;
  width: 250px;
}

@media (max-width: 1200px) {
  .sec._03 .sec3box .sec3-text h3 {
    font-size: 22px;
  }
}

@media (max-width: 1000px) {
  .sec._03 .sec3box .sec3-text h3 {
    width: 100%;
    border-bottom: 1px solid #ccc;
  }
}

.sec._03 .sec3box .sec3-text img {
  position: absolute;
  max-width: 356px !important;
}

@media (max-width: 1000px) {
  .sec._03 .sec3box .sec3-text img {
    display: none;
  }
}

.sec._03 .sec3box .sec3-text img.bottom-img {
  top: -15px;
}

.sec._03 .sec3box .sec3-text p {
  width: 250px;
  margin-top: 5px;
}

@media (max-width: 1000px) {
  .sec._03 .sec3box .sec3-text p {
    width: 100%;
  }
}

.sec._03 .sec3box .sec3-text1,
.sec._03 .sec3box .sec3-text2,
.sec._03 .sec3box .sec3-text3 {
  left: 0;
}

@media (max-width: 1000px) {
  .sec._03 .sec3box .sec3-text1,
  .sec._03 .sec3box .sec3-text2,
  .sec._03 .sec3box .sec3-text3 {
    padding: 0 10px;
  }
}

.sec._03 .sec3box .sec3-text1,
.sec._03 .sec3box .sec3-text4 {
  top: -5%;
}

.sec._03 .sec3box .sec3-text2,
.sec._03 .sec3box .sec3-text5 {
  top: 30%;
}

.sec._03 .sec3box .sec3-text3,
.sec._03 .sec3box .sec3-text6 {
  top: 65%;
}

.sec._03 .sec3box .sec3-text4,
.sec._03 .sec3box .sec3-text5,
.sec._03 .sec3box .sec3-text6 {
  right: 0;
}

.sec._03 .sec3box .sec3-text4 h3,
.sec._03 .sec3box .sec3-text5 h3,
.sec._03 .sec3box .sec3-text6 h3 {
  margin: 0 0 0 auto;
  padding-left: 30px;
}

@media (max-width: 1000px) {
  .sec._03 .sec3box .sec3-text4 h3,
  .sec._03 .sec3box .sec3-text5 h3,
  .sec._03 .sec3box .sec3-text6 h3 {
    padding: 0 10px;
  }
}

.sec._03 .sec3box .sec3-text4 img,
.sec._03 .sec3box .sec3-text5 img,
.sec._03 .sec3box .sec3-text6 img {
  right: 0;
}

.sec._03 .sec3box .sec3-text4 p,
.sec._03 .sec3box .sec3-text5 p,
.sec._03 .sec3box .sec3-text6 p {
  margin: 5px 0 0 auto;
  padding-left: 30px;
}

@media (max-width: 1000px) {
  .sec._03 .sec3box .sec3-text4 p,
  .sec._03 .sec3box .sec3-text5 p,
  .sec._03 .sec3box .sec3-text6 p {
    padding: 0 10px;
  }
}

.sec._04 ._04-flex {
  max-width: 1200px;
}

.sec._04 ._04-flex .box {
  padding: 0 20px;
}

.sec._05 ._05-flex {
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .sec._05 ._05-flex .box {
    width: 50%;
  }
}

.sec._05 ._05-flex img {
  padding: 10px;
  width: 100%;
}

.sec._06 ._06-flex {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

@media (max-width: 1200px) {
  .sec._06 ._06-flex {
    flex-wrap: wrap;
  }
}

.sec._06 ._06-flex .box {
  width: 18%;
  text-align: center;
  display: inline-block;
  margin: 50px auto 0;
  position: relative;
  z-index: 2;
}

.sec._06 ._06-flex .box:before {
  position: absolute;
  content: "";
  background-color: #175466;
  top: 50%;
  right: 0;
  width: 100%;
  height: 2px;
  right: -50%;
  z-index: -1;
}

@media screen and (max-width: 640px) {
  .sec._06 ._06-flex .box:before {
    display: none;
  }
}

@media (max-width: 1200px) {
  .sec._06 ._06-flex .box {
    width: 33%;
  }
}

@media screen and (max-width: 640px) {
  .sec._06 ._06-flex .box {
    width: 50%;
  }
}

.sec._06 ._06-flex .box h3 {
  color: #175466;
  margin-bottom: 20px;
}

.sec._06 ._06-flex .box img {
  position: relative;
}

@media screen and (max-width: 640px) {
  .sec._06 ._06-flex .box img {
    width: 65%;
  }
}

.sec._06 ._06-flex .box:last-child:before {
  display: none;
}

@media (max-width: 1200px) {
  .sec._06 ._06-flex .box:nth-child(3):before {
    display: none;
  }
}

.sec._07 .box {
  padding: 0 40px;
}

@media screen and (max-width: 834px) {
  .sec._07 .box {
    margin-top: 50px;
  }
}

@media screen and (max-width: 640px) {
  .sec._07 .box {
    padding: 0;
  }
}

.sec._07 .box .midashi {
  color: #175466;
  font-family: noto-sans-cjk-jp, sans-serif;
  font-weight: 900;
  font-style: normal;
  text-align: center;
  margin-bottom: 25px;
}

@media screen and (max-width: 640px) {
  .sec._07 .box img {
    padding: 0 50px;
  }
}

.scroll-top {
  width: 80px;
  height: 80px;
  background-color: #175466;
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 99;
}

.scroll-top:before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  border: 0;
  border-top: solid 3px #fff;
  border-right: solid 3px #fff;
  transform: rotate(-45deg);
  top: 45%;
  left: 0;
  right: 0;
  margin: auto;
}

@media screen and (max-width: 480px) {
  .Form {
    margin-top: 40px;
  }
}

.contact_bg {
  background: #175466;
}

.contact_bg h2 {
  text-align: center;
  margin-bottom: 50px;
}

.contact_bg h2 span {
  font-size: 46px;
  font-weight: 900;
  color: #fff;
  border-bottom: 1px solid #fff;
  padding-bottom: 15px;
}

@media screen and (max-width: 834px) {
  .contact_bg h2 span {
    font-size: 32px;
  }
}

@media screen and (max-width: 640px) {
  .contact_bg h2 span {
    font-size: 32px;
  }
}

.contact_bg .cl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25%;
  margin-right: 30px;
}

@media screen and (max-width: 960px) {
  .contact_bg .cl {
    width: 100%;
  }
}

.contact_bg .erform {
  width: 75%;
}

.contact_bg .form_cl {
  border-bottom: 1px solid #ddd;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact_bg .form_cl:first-of-type {
  padding: 0 0 30px;
}

@media screen and (max-width: 960px) {
  .contact_bg .form_cl {
    display: block;
  }
  .contact_bg .form_cl .erform {
    margin-top: 15px;
    width: 100%;
  }
}

.contact_bg .form_cl .hissu, .contact_bg .form_cl .nini {
  color: #fff;
  background: #ef4877;
  border-radius: 5px;
  max-width: 70px;
  width: 100%;
  padding: 8px;
  display: inline-block;
  text-align: center;
  line-height: 1em;
}

.contact_bg .form_cl .nini {
  background: #427dad;
}

.contact_bg .form_add input {
  margin-top: 10px;
  max-width: 410px !important;
}

.contact_bg .form_add input:nth-of-type(1) {
  max-width: 210px !important;
  margin-bottom: 10px;
}

.contact_bg .form_mail input:nth-of-type(1) {
  margin-bottom: 10px;
}

.contact_bg form {
  padding: 50px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: #fff;
  font-size: 14px;
}

.contact_bg form h1 {
  letter-spacing: 2px;
  margin: 0 0 15px;
  font-size: 22px;
  font-weight: bold;
  color: #333333;
}

.contact_bg form > div input[type=text], .contact_bg form > div input[type=email], .contact_bg form > div textarea, .contact_bg form > div select {
  display: block;
  max-width: 640px;
  width: 100%;
  height: 38px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #f6f6f6;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

@media screen and (max-width: 960px) {
  .contact_bg form > div input[type=text], .contact_bg form > div input[type=email], .contact_bg form > div textarea, .contact_bg form > div select {
    max-width: 100%;
  }
}

.contact_bg form > div textarea {
  height: auto;
}

.contact_bg input[type=radio] {
  margin-left: 25px;
  margin-top: 8px;
}

.contact_bg .container label + div {
  width: 75%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.contact_bg button {
  color: #fff;
  background-color: #ff7800;
}

.contact_bg body > div > a > button {
  margin-left: 0;
  margin: 0 auto;
  display: block;
}

.contact_bg a + button {
  margin-left: 20px;
}

.contact_bg a {
  text-decoration: none;
}

.contact_bg a:focus, .contact_bg a:hover {
  outline: none;
  text-decoration: none;
}

.contact_bg input::-webkit-input-placeholder {
  color: #777;
}

.contact_bg input[type=button] {
  padding: 10px 13px 11px;
  font-size: 18px;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  border-radius: 4px;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid #c3c3c3;
  color: #7c7c7c;
  background: #ffffff;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.28);
  margin-left: 180px;
  margin-right: 25px;
}

.contact_bg input[type=button] + button {
  display: inline-block;
}

.contact_bg input + div, .contact_bg select + div, .contact_bg textarea + div {
  color: red;
  margin-bottom: -10px;
  text-align: left;
}

.contact_bg .error-form {
  border-color: #fb9a93 !important;
}

form {
  background-color: #f7f7f7;
  padding: 30px;
  border-radius: 5px;
  font-size: 14px;
  margin: 50px 0;
}

.confi_cl {
  margin: 15px 0;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}

.confi_cl label p {
  margin-top: 10px;
}

.confi_cl label {
  font-weight: bold;
}

.contact-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.form_btn, .input_btn, .re_form_btn {
  padding: 15px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  line-height: 1em;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  color: #ffffff;
  background: #ff7800;
  width: 170px;
}

.input_btn {
  padding: 15px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  line-height: 1em;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  color: #ffffff;
  background: #ff7800;
  width: 170px;
  margin: 50px auto 0;
  display: block;
}

.re_form_btn {
  padding: 15px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  line-height: 1em;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  color: #ffffff;
  background: #ff7800;
  width: 170px;
  background: #595959;
  margin-right: 30px !important;
}

.btnwrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnwrap .form_btn, .btnwrap .input_btn, .btnwrap .re_form_btn {
  margin: 50px 0;
}

.form_end {
  background: #f7f7f7;
  padding: 30px;
  text-align: center;
  margin: 50px 0;
}

.form_end h1 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 30px;
}

.thank_txt {
  margin-bottom: 30px;
}
